// Dashboard.module.scss
.dashboardContainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
}

.card {
    width: 250px;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    position: relative;
}

.cardTitle {
    font-size: 1.2rem;
    font-weight: 600;
}

.cardValue {
    font-size: 2.5rem;
    font-weight: 700;
}

.cardIcon {
    position: absolute;
    right: 20px;
    top: 20px;
    opacity: 0.3;
    font-size: 3rem !important;
}

.moreInfo {
    margin-top: 20px;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    display: flex;
    align-items: center;
}

.moreInfoIcon {
    margin-left: 5px;
}

.gradientBlue {
    background: linear-gradient(45deg, #42a5f5, #478ed1);
}

.gradientGreen {
    background: linear-gradient(45deg, #66bb6a, #4caf50);
}

.gradientYellow {
    background: linear-gradient(45deg, #fdd835, #fbc02d);
}

.gradientRed {
    background: linear-gradient(45deg, #ef5350, #e53935);
}