@import '../theme/colors.scss';

.tabContainer {
    background-color: $primary-main;
    border-radius: 50px;
    display: inline-block;
    margin-bottom: 20px;

    .selectedTab {
        background-color: $gray-main !important;
        border-radius: 50px !important;
        color: $primary-main !important;
        margin: 5px !important;
        text-transform: none !important;
        padding: 0 10px !important;
        min-height: 41px !important;
        font-weight: 500 !important;
        font-size: 14px !important;
    }

    .tab {
        color: $secondary-main !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        margin: 5px !important;
        text-transform: none !important;
        padding: 0 10px !important;
        min-height: 41px !important;
    }

    .indicator {
        background-color: transparent !important;
    }
}