@import '../../shared/theme/colors.scss';

.main {
    flex-grow: 1;
    padding: 3rem 1rem 1rem 1rem
}

.listContainer {
    margin-left: 8px !important;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}

.selectedItem {
    background-color: $gray-main !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
}

.selectedItem::before {
    content: '';
    position: absolute;
    top: -20px;
    right: 0;
    width: 20px;
    height: 20px;
    box-shadow: 5px 5px 0px 5px $gray-main !important;
    border-bottom-right-radius: 20px;
    background: transparent;
}

.selectedItem::after {
    content: '';
    position: absolute;
    bottom: -20px;
    right: 0;
    width: 20px;
    height: 20px;
    border-top-right-radius: 20px;
    box-shadow: 5px -5px 0px 5px $gray-main;
    background-color: transparent;
}


.itemButton {
    min-height: 48px;
    padding: 0 20px;
}


.logo {
    align-self: flex-start !important;
}


.profileButton {
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.profileButton:hover {
    transition: all ease-in-out 0.3;
}

.profileAvatar {
    width: 40px;
    height: 40px;
    border: 3px solid $gray-light;
}

.nameHeading {
    font-size: 14px !important;
    font-weight: 500;
}

.roleHeading {
    font-size: 12px !important;
    font-weight: 400px;
    text-transform: capitalize;
}

.toolbarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}