// Primary Colors
$primary-main: #1f1f1f;

// Secondary Colors
$secondary-main: #f0fc6c;

// Gray Colors
$gray-main: #f3f4f6;
$gray-light: #e9e9e9;

// White Colors
$white-main: #FFFFFF;

// Error Colors
$error-main: #DF1D5A;

// Success Colors
$success-main: #55BE38;

// Background Colors
$background-default: #f3f4f6;
$background-paper: #f3f4f6;

// Text Colors
$text-primary: #1f1f1f;

$box-shadow : rgba(0, 0, 0, 0.16) 0px 1px 4px