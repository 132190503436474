@import '../../shared/theme/colors.scss';

.userContainer {
    padding: 1rem;
    background-color: $gray-light;
    border-radius: 10px;
    box-shadow: $box-shadow;
    // min-height: 80vh;
    gap: 12px;
    overflow: hidden !important;
}

.searchBar {
    background-color: $gray-main !important;
}

.formContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.menuItem {
    display: flex;
    align-items: center;
    gap: 5px;
}