@import '../../../shared/theme/colors.scss';

.signatureContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.signatureCanvas {
    border: 1px solid black;
}


.btnContainer {
    display: flex;
    padding-top: 1rem;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.dobsContainer {
    border: 1px solid #d3d3d3;
    background-color: #f9f9f9;
    box-shadow: $box-shadow;
    padding: 1rem;
    border-radius: 10px;
}


.memberInfoCard {
    margin: 20px auto;
    padding: 20px;
    background-color: $gray-light !important;
    border-radius: 12px;
}

.memberHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .avatar {
        margin-right: 15px;
        width: 60px;
        height: 60px;
    }

    h5 {
        font-weight: bold;
        font-size: 1.5rem;
    }
}

.infoItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .icon {
        margin-right: 8px;
        color: $primary-main;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

@media (max-width: 600px) {
    .memberInfoCard {
        padding: 15px;
    }

    .memberHeader {
        h5 {
            font-size: 1.2rem;
        }
    }
}