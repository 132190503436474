.loginBackground {
    height: 100vh;
    background-image: linear-gradient(to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0)),
        url('../../../assets/img/bg1.jpg');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.logo {
    width: 10rem;
    height: 10rem;
    align-self: center;
}