body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-editor {
  min-height: 400px;
  /* This sets the border around the editor content */
  padding: 10px;
  /* Optional padding for better look */
}

.ql-editor p {
  margin: 0;
  /* Remove default margin */
  padding: 0;
  /* Remove default padding */
  line-height: 1.5;
  /* Adjust the line height */
}

.ql-editor ul,
.ql-editor ol {
  margin: 0;
  /* Ensure lists don't have extra margins */
  padding-left: 20px;
  /* Add some padding for list indentation */
}

.ql-editor h2,
.ql-editor h3 {
  margin: 0;
  /* Adjust margins for headings */
  padding: 0;
  line-height: 1.2;
}

/* Adjust spacing between block elements */
.ql-editor>*+* {
  margin-top: 10px;
  /* Set margin between paragraphs, headings, lists, etc. */
}