@import '../../components/shared/theme/colors.scss';

.card {
    border: 1px solid $primary-main;
    border-radius: 8px;
    padding: 20px;
    background-color: $gray-main;
    box-shadow: $box-shadow;
    position: relative;
    overflow: hidden;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: gray;
}

.editButton {
    margin-right: 10px;
    color: #1976d2;
}

.printButton {
    color: #4caf50;
}

.content {
    padding: 15px;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
}